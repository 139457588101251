import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

const WebsiteFrame = ({ url, index }) => {
  const [isWorking, setIsWorking] = useState(null);
  const [showUrl, setShowUrl] = useState(false);
  const iframeRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const checkWebsite = async () => {
      try {
        console.log(`Checking website: ${url}`);
        await axios.get(url, { timeout: 5000 });
        setIsWorking(true);
        console.log(`Website ${url} is reachable.`);
      } catch (error) {
        setIsWorking(false);
        console.error(`Error checking website ${url}:`, error);
      }
    };

    // Initial check on mount
    checkWebsite();

    // Set interval to check every 30 seconds
    const interval = setInterval(() => {
      checkWebsite();
    }, 30000);

    return () => clearInterval(interval); // Clean up interval
  }, [url]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && iframeRef.current) {
          iframeRef.current.src = url; // Load iframe when it comes into view
        }
      });
    });

    if (iframeRef.current) {
      observerRef.current.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        observerRef.current.unobserve(iframeRef.current);
      }
    };
  }, [url]);

  const handleToggle = () => {
    setShowUrl(!showUrl);
  };

  return (
    <div
      style={{
        width: '427px',
        height: '525px',
        position: 'relative',
        border: isWorking === null ? '1px solid #ddd' : isWorking ? '2px solid green' : '2px solid red',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
      onClick={handleToggle}
    >
      <div style={{ position: 'absolute', top: '5px', left: '5px', zIndex: '1', background: '#d1de2e', padding: '5px' }}>
        <strong>{index + 1}</strong>
      </div>
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        sandbox="allow-scripts allow-same-origin"
        style={{ display: showUrl ? 'none' : 'block' }}
      />
      <div style={{ display: showUrl ? 'block' : 'none', marginTop: '20px' }}>
        <p>{url}</p>
      </div>
    </div>
  );
};

export default WebsiteFrame;
