import React from 'react';
import WebsiteFrame from './WebsiteFrame';
import './WebGrid.css';

const websites = [
  'http://seahorseconsulting.in/',
  'https://launchdome.in/',
  'https://vplschool.com/',
  'http://golfdesignindia.com/',
  'http://thedestinyhealer.com/',
  'https://starisgroup.com/',
  'http://careermanagerconsulting.com/',
  'http://akshithonda.com/',
  'https://healtab.com/',
  'https://galaxycamlights.com/',
  'https://finconcile.com/',
  'https://www.conceptualise.in/',
  'https://multitvsolution.com/',

  'https://www.endeavorrecyclers.com/',
  'https://arthaseniorcare.com/',
  'https://ykorthopaedics.com/',
  'http://orthocure.co.in/',
  'https://healthgrate.com/',
  'https://business-salt.com/',
  'https://aromasouls.com/',
  'https://knockwalk.com/',
  'http://igrconsultantsllp.com/',
  'http://ipledgetosavewater.org/',
  'https://isupportkamalnath.com/',
  'https://jayantmaini.com/',
  'https://luxoryheights.com/',
  'https://aravalia.com/',
  'http://nohara.in/',
  'https://casadeluxe.in/',
  'https://rsjusthair.com/',
  'http://saiyum.com/',
  'https://sbsss.co.in/',
  'http://technovation.co.in/',
  'https://trustech.com.au/',
  'https://totallyfit.in/',
  'https://vanddnabhargave.com/',
  'https://vishvahospitality.com/',
  'https://wellnesscoachindia.com/',
  'https://zapmiles.com/',
  'https://zielfintech.com/',
  'http://appletree.co.in/',
  'http://aryaveco.com/',
  'http://gethirednow.in/',
  'https://egllabs.com/',
  'https://nwdgolf.com/',
  'http://stutimmishra.com/',
  'https://knotraglobal.com/',
  'https://endeavorpower.in/',
  'http://ruhifoundation.com/',
  'https://finbridgeadvisors.co.uk/',
  'https://enlargenet.com/',
  'https://dynotech-solutions.com/',
  'http://dspaze.com/',
  'https://datatech.agency/',
  'https://astrologervastu.com/',
  'https://dauphingroup.in/',
  'https://mergencompass.com/',
  'https://astrologervastu.in/',
  'http://carmover.in/',
  'http://akasauliggaon.com/',
  'https://ahacatering.com/',
  'http://xy-ads.com/',
  'http://soundville.in/',
  'https://seniorcaregenie.com/',
  'http://omkalyanam.com/',
  'http://kritibuildwell.com/',
  'http://healthybio.in/',
  'http://goalubuild.com/',
  'http://bronx.co.in/',
  'http://banjarava.com/',
  'https://enclargenet.com/',
  'http://pawsonpaths.in/',
  'http://aatmikk.com/',
  'https://vardhmansportsacademy.com/',
  'http://spycesauce.in/',
  'http://abdfirm.com/',
  'https://digiproductsonline.in/',
  'https://uniformrepublique.com/',
  'https://www.mcidigital.in/',
  'http://auggmin.com/'
];

const WebGrid = () => {
  return (
    <div>
      <div className="web-grid">
        {websites.map((url, index) => (
          <div key={index} className="web-grid-item">
            <WebsiteFrame url={url} index={index} />
          </div>
        ))}
      </div>
      <div className="footer">
        <h2>Websites URLs:</h2>
        <ul className="url-list">
          {websites.map((url, index) => (
            <li key={index}>
              <strong>{index + 1}</strong>: <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WebGrid;
